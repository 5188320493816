import ExcelJS from 'exceljs'

export default class ExcelHelper {

    static createWorkbook () {
        const workbook = new ExcelJS.Workbook()
        workbook.creator = 'ОКО ВПР'
        workbook.created = new Date()
        return workbook
    }

    static createWorksheet (workbook, name, props = {}) {
        return workbook.addWorksheet(name, props)
    }
}