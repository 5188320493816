<template>
    <v-dialog
      v-model="show"
      :width="datepickerWidth"
    >
      <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="rangeFancyText"
                :error-messages="errorMessages"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                :disabled="disabled"
                placeholder="Выберите диапазон дат"
                prepend-inner-icon="mdi-calendar"
                hide-details
                :append-icon="rangeFancyText ? 'mdi-close' : null"
                :color="$const.color.primary"
                style="max-width: 270px;"
                @click:append="() => closeDialog(true)"
            ></v-text-field>
      </template>

      <v-card class="pb-5">
        <v-date-picker 
            v-model="filterLocal.range"
            :width="datepickerWidth"
            persistent
            no-title
            scrollable
            :color="$const.color.primary"
            range
            :disabled="disabled"
            :allowed-dates="checkDate"
            :weekday-format="weekdayFormat"
            :first-day-of-week="1"
        />
        <v-divider class="mb-2" />
        <div class="d-flex flex-nowrap justify-center align-center">
            <v-btn
                outlined
                :disabled="!filterLocal.range || !checkDate(filterLocal.range[0]) || filterLocal.range[1] && (!checkDate(filterLocal.range[1])) || disabled"
                height="40px"
                class="button-stylized-blue mr-5"
                @click="closeDialog"
            >
                Выбрать
            </v-btn>
            <v-btn
            outlined
                :disabled="disabled"
                height="40px"
                class="button-stylized"
                @click="() => closeDialog(true)"
            >
                Сбросить
            </v-btn>
        </div>
      </v-card>
    </v-dialog>
</template>

<script>
import TimeHelper from '@/helpers/TimeHelper'

export default {
  props: {
    value: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    errorMessages: { type: Array, default: null },
    checkDate: { type: Function, default: (() => true) }
  },
  data () {
    return {
      show: false,
      updateIndex: 0,
      filterLocal: {
        range: []
      }
    }
  },
  computed: {
    datepickerWidth () {
      return '25rem'
    },
    rangeTimestamp () {
        this.updateIndex

        if (this.filterLocal.range.length === 0) return []

        let dates = this.filterLocal.range.length === 2 ? this.filterLocal.range : [this.filterLocal.range[0], this.filterLocal.range[0]]

        if (typeof dates[0] === 'string')
            dates = dates.map(timeString => this.$moment(timeString, 'YYYY-MM-DD').unix())

        dates.sort()

        const unixStartOfDay = this.$moment(dates[0], 'X').startOf('day').unix()
        const unixEndOfDay = this.$moment(dates[1], 'X').endOf('day').unix()

        return [unixStartOfDay, unixEndOfDay]
    },
    rangeFancyText () {
      if (!this.rangeTimestamp?.length) { return null }
      return this.rangeTimestamp.map(timestamp => this.$moment(timestamp * 1000).format('DD.MM.YYYY')).join(' - ')
    }
  },
  created () {
    const value = this.value.map(dateValue => {
        return typeof dateValue === 'number' ?
            this.$moment(dateValue, 'X').format('YYYY-MM-DD') :
            dateValue
    })
    
    this.filterLocal.range = value
  },
  methods: {
    closeDialog (clearDate = false) {
        if (clearDate === true) {

            this.filterLocal.range = []
            this.updateIndex++
        }

        this.show = false
        this.$emit('input', this.rangeTimestamp)
    },
    weekdayFormat (date) {
        return TimeHelper.weekdayFormat(date)
    }
  }
}
</script>